import { useEffect, useState, useCallback} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';
import OtpVerification from '../login/otpVerification';
import ErrorModal from './errorModal';
import CustomToast from '../Toast';
import Modal from 'react-modal'
import './toast.css';
import Messages from '../messages';
import { debounce } from '../../utils/debounce'; // Import the debounce function

const AcceptInvitationOrgComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);
  const [error, setError] = useState('');
  const [otpData, setOtpData] = useState(null);
  const [showOtpComponent, setShowOtpComponent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [redirectTimer, setRedirectTimer] = useState(5);
  const [message, setMessage] = useState('')
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [isOrgUser, setIsOrgUser] = useState("org-user");

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const token = params.get('token');

  //   if (token) {
  //     verifyToken(token);
  //   } else {
  //     navigate('/error');
  //   }
  // }, [location.search, navigate]);


  const handleAPIError = (error) => {
    if (error && error.message && error.message.includes('Org user already registered')) {
      setShowErrorPopup(true)
      setMessage('Org user already registered.')
      openVideoModal()
    } else {
      setError(error.message || 'Something went wrong. Please try again.');
    }
  };

  const verifyToken = useCallback(
    debounce(async (paramsToken) => {
      try {
        const data = {
          token: paramsToken,
          status: "ACCEPTED",
          dname: "OPPO",
          did: "2526"
        };
        const result = await apiService.post('accept-org', data);
        if (result && result.status === "success") {
          result.data['type'] = 'org-user'
          setOtpData(result.data);
          setShowOtpComponent(true);
          CustomToast.success('OTP send successfully')
        } else {
          setShowOtpComponent(false);
        }
      } catch (error) {
        setShowOtpComponent(false);
        handleAPIError(error);
        CustomToast.error(error);
      }
    }, 300), // Adjust the debounce delay as needed
    []
  );

  const verifyToken1 = async (paramsToken) => {
    try {
      // const appToken = apiService.getAuthToken();
      const data = {
        token: paramsToken,
        status: "ACCEPTED",
        dname: "OPPO",
        did: "2526"
      };
      const result = await apiService.post('accept-org', data);
      if (result && result.status === "success") {
        result.data['type'] = 'org-user'
        setOtpData(result.data);
        setShowOtpComponent(true);
        CustomToast.success('OTP send successfully')
      } else {
        setShowOtpComponent(false);
      }
    } catch (error) {
      setShowOtpComponent(false);
      handleAPIError(error);
      CustomToast.error(error);
    }
  };

  const openVideoModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    console.log("dsas", process.env.REACT_APP_ENV)
    if (redirectTimer === 0 && showErrorPopup) {
      // Redirect and close the modal when the timer reaches 0
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      if (token) {
        window.location.href = `${process.env.REACT_APP_LOGIN_URL}?token=${token}`
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL
      }
      // process.env.REACT_APP_ENV == "development" ? window.location.href = 'https://staging.inclusional.com' : window.location.href =`https://site.inclusional.com`
      setIsModalOpen(false);
    }
  }, [redirectTimer, navigate]);

  const closeVideoModal = () => {
    setIsModalOpen(false);
  };

  function redirectTimes(seconds) {
    const minutes = Math.floor(seconds / 15);
    const remainingSeconds = seconds % 15;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRedirectTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timerInterval);
  }, []);

  return (
    <div>
      {error && (
       <Messages errorMessage={error} />
      )}
      {/* {showOtpComponent && ( */}
        <div>
          <OtpVerification userDetails={otpData} isUser={isOrgUser} />
        </div>
      {/* )} */}
       {showErrorPopup && (
        <Messages
          modalMessage={message}
          redirectTimer={redirectTimer}
        />
      )}
    </div>
  );
};

export default AcceptInvitationOrgComponent;