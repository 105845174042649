import React, { useState, useRef, useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import './inviteToCourse.css';
import apiService from '../../../services/apiService';
import Modal from 'react-modal';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';

const CsvFileUpload = () => {
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [userData, setUserData] = useState()
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const inputRef = useRef(null);
  const [csvFile, setCsvFile] = useState()
  const [loader, setLoader] = useState(false)
  const [count, setCount] = useState('')

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openCsvModal = () => {
    setIsCsvModalOpen(true);
  };

  const closeCsvModal = () => {
    setIsCsvModalOpen(false);
  };

  useEffect(()=>{
    let userDetail = sessionStorage.getItem("userDetail")
    if(userDetail) {
        userDetail = JSON.parse(userDetail)
        setUserData(userDetail)
    }
    fetchOrgEntities()
  }, [])

  const fetchOrgEntities = (userDetail) => {
    const token = apiService.getAuthToken()
    const org_id = localStorage.getItem('ORGID')
    apiService.getv1(`org-enitity/${org_id}`, token).then(
        (response) => { 
          setOptions(response.data)
        },
        (error) => {
        console.error("Error fetching data", error);
        CustomToast.error('Error while fetching org entity')
        }
    );
  };


useEffect(() => {
    if (options && options.length > 0) {
        setSelectedValue(options[0].org_entity);
    }
}, [options]);  

const handleInputChange = (e) => {
  setSelectedValue(e.target.value);
};

// const handleKeyDown = (e) => {
//   if (e.key === 'Enter') {
//     addOption();
//   }
// };

// const addOption = () => {
//   if (selectedValue.trim() !== '') {
//     setOptions((prevOptions) => [...prevOptions, selectedValue]);
//     setSelectedValue('');
//     console.log('Selected Value:', selectedValue);
//   }
// };

const onDrop = (acceptedFiles) => {
  const file = acceptedFiles[0];
  setFile(file);
  setSelectedFileName(file.name);
};

const { getRootProps, getInputProps } = useDropzone({
  onDrop,
  accept: ['.csv'],
});

const handleSubmit = (e) => {
  e.preventDefault();
  // console.log('File:', file);
};

const clearTheForm = () => {
  setFile(null);
  setSelectedValue(null);
  setSelectedFileName(null)
};


const fetchData = async () => {
  try {

    if (!file) {
      CustomToast.info('Please upload a file.');
      return;
    }
  
    const fileType = file.type;
    if (fileType !== 'text/csv') {
      CustomToast.info('Please upload a CSV file.');
      return;
    }
    setLoader(true)
    let courseData =localStorage.getItem("selectedCourseObj")
    courseData = JSON.parse(courseData)
    let orgData =localStorage.getItem("orgData")
    orgData = JSON.parse(orgData)
    console.log(file);
    const formData = new FormData();
    formData.append('image', file);
    formData.append('orgid', orgData.org_id);
    formData.append('org_course_id', courseData?.org_course_id);
    formData.append('course_id', courseData?.course_id);
    formData.append("org_name", orgData?.org_name.trim());
    if(selectedValue) {
      formData.append("org_entity", selectedValue)
    }
      // const token = "yUsakGX2WIRQi26eRlsleUU5Xyzh72VwlZHKb/SVtNo="
      const token = apiService.getAuthToken()
      const result = await apiService.postFormdata('bulk-send-and-assign-course', formData, token);
      if (result && result.status === "success") {
        // console.log(result, "kcdfnvidsfnvids");
        // setCourseData(result.data);
        clearTheForm() 
       if(result?.data == "Invitation sent successfully") {
        CustomToast.success('Bulk invitation sent successfully.');
        setLoader(false)
      } else {
        setCount(result?.data?.count || 0)
        setCsvFile(result?.data?.file)
        openCsvModal();
        setLoader(false)
      }
      }
    } catch (error) {
      //   setError('Error fetching user data');
       //   setError('Error fetching user data');
       clearTheForm()
       setLoader(false)
       if(error && error.message && error.message.includes('Course invite already sent.')) {
        CustomToast.info('The invitation has already been sent to the user.')
      } else if(error && error.message && error.message.includes('Error occurred while sending invitations: Error: Error while creating temp user: Course invite already sent by some other organization.')){ 
        CustomToast.info('Course invite already sent by some other organization.') 
      } else if(error && error.message && error.message.includes('Not enough licenses available')) {
        CustomToast.info('Insufficient licenses')
      } else if(error && error.message && error.message.includes('Image File is not a spreadsheet')) {
        CustomToast.info('File format should be CSV.')
      } else if(error && error.message && error.message.includes("Licenses are not enough to accommodate the requested file")) {
        CustomToast.info('Insufficient licenses')
      } else if(error && error.message && error.message.includes("Licenses are not available for provided course")) {
        CustomToast.info('Licenses are not available for provided course')
      } else if(error && error.message && error.message.includes("Please provide the proper email address")) {
        CustomToast.info('Please provide the proper email address')
      } else if(error && error.message && error.message.includes("Please provide the proper email address")) {
        CustomToast.info('Please provide the proper email address')
      } else if(error && error.message && error.message.includes("Please don't upload blank files")) {
        CustomToast.info('No data found in CSV')
      }
    }
};


return (
  <div className='m-3 position-relative'>
   { loader ? <Loader/> :
    <form className='bulk-upload mb-4' onSubmit={handleSubmit}>
    {options && options.length > 0 ? <div className="form-group">
            <label className='mb-2 fw-bold'>Entity*</label>
            <select 
              className='form-select position-relative mb-5'
              value={selectedValue?.org_id}
              onChange={handleInputChange} 
              required
              >
              {options.map((option) => (
                <option key={option.org_id} value={option.org_entity}>
                  {option.org_entity}
                </option>
              ))}
              </select>
              {/* <input
                type="text"
                name='selectedValue'
                value={selectedValue || ''}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className='form-control'
                placeholder="Add option if not available in list"
                ref={inputRef}
              /> */}
          </div> : null}
      <div {...getRootProps()} className='dropzone'>
        <input {...getInputProps()} />
        <p className='font-18'><strong>Upload your file here</strong> </p>
        <button type="button" className='file-upload-btn'>
          Choose File
        </button>
        {selectedFileName && (
          <p className='selected-file-name'>{selectedFileName}</p>
        )}
      </div>
      <button type="submit" className='btn btn-primary w-100 mt-4' onClick={fetchData}>
        Send Invite
      </button>
    </form> }
      <p className='download-csv text-center mt-3 mb-0'>
      <a href={process.env.PUBLIC_URL + '/templates/bulkinvitation.csv'} className='font-weight-600'>
            Download CSV template here
      </a>
      </p>
    {/* <button className="btn btn-primary" onClick={openModal}>click here </button> */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className='Modal bg-white p-3'
      >
        {/*<button type="button" className="close text-white" onClick={closeModal}><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
        <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
        </svg></button>*/}

        <div className="py-4 px-5 w-100 text-center">
          <p className="fw-bold font-18 mb-0">Bulk invitation sent successfully.</p>
          <button onClick={closeModal} className='btn btn-md btn-primary mt-4 py-2'>Ok</button>
        </div>
      </Modal>

    {/* <button className="btn btn-primary" onClick={openCsvModal}>click here </button> */}

      <Modal
        isOpen={isCsvModalOpen}
        onRequestClose={closeCsvModal}
        contentLabel="Modal"
        className='Modal bg-white w-25 p-3'
      >
        <button type="button" className="close text-white" onClick={closeCsvModal}><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
        <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
        </svg></button>

        <div className="py-4 px-4 w-100 text-center">
        <p className='font-16 fw-bold mt-3'>Bulk invite failed for {count} users. Please download the CSV file to view detailed errors and retry.</p>
        <div className='text-center mt-4'>
          <a href={csvFile} className='btn btn-primary py-2' download>
            Download CSV
          </a>
        </div>
      </div>
      </Modal>
  </div>
);
};

export default CsvFileUpload;
