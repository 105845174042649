import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './inviteToCourse.css';
import apiService from '../../../services/apiService';
import Modal from 'react-modal';
import CustomToast from '../../Toast';

const InviteCourseForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [designation, setDesignation] = useState('');
    const [department, setDepartment] = useState('');
    const [userLocation, setUserLocation] = useState('');
    const [entity, setEntity] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [userData, setUserData] = useState()
    const [userInfo, setUserInfo] = useState();
    const [selectedValue, setSelectedValue] = useState('');
    const inputRef = useRef(null);

    useEffect(()=>{
      let orgData =localStorage.getItem("orgData")
      if(orgData) {
        orgData = JSON.parse(orgData)
          setUserData(orgData)
      }
      getOrgUserInfo();
      fetchOrgEntities(orgData)
    }, [])

  const getOrgUserInfo = async () => {
      try {
          const token = apiService.getAuthToken()
          const result = await apiService.get('get-org-user-info', token);
          if(result && result.status == "success") {
              setUserInfo(result?.data);
          }
          console.log("UserInfo:", userInfo);
      } catch (error) {
         // console.error(error);
          CustomToast.error('Error while fetching user info')
      }
  };

  const fetchOrgEntities = (userDetail) => {
    const token = apiService.getAuthToken()
    const org_id = localStorage.getItem('ORGID')
    apiService.getv1(`org-enitity/${org_id}`, token).then(
        (response) => { 
          setOptions(response.data)
        },
        (error) => {
        console.error("Error fetching data", error);
        CustomToast.error('Error while fetching org entity')
        }
    );
  };

  useEffect(() => {
    if (options && options.length > 0) {
        // Set the initial value to the first course in the list
        setSelectedValue(options[0].org_entity);
    }
}, [options]);
  
  const handleInputChange = (e) => {
    setSelectedValue(e.target.value);
  };
  
    // const handleKeyDown = (e) => {
    //   if (e.key === 'Enter') {
    //     addOption();
    //   }
    // };
  
    // const addOption = () => {
    //   if (selectedValue.trim() !== '') {
    //     setOptions((prevOptions) => [...prevOptions, selectedValue]);
    //     setSelectedValue('');
    //     console.log('Selected Value:', selectedValue);
    //   }
    // };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
       // validateEmail(newEmail);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };  
    
    const validateEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailPattern.test(email);
      if (!isValid) {
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
      return isValid
    };
      
    const handlePhoneChange = (e) => {
      const newPhoneNumber = e.target.value;
      setPhone(newPhoneNumber);
      //validatePhone(newPhoneNumber);
    };
      
    const validatePhone = (phone) => {
      if (isNaN(phone)) {
        setPhoneError('Please enter a valid phone number.');
        return;
      }
      const phonePattern = /^[0-9]{10}$/;
      const isValid = phonePattern.test(phone);
      if (!isValid) {
        setPhoneError('Please enter a valid 10-digit phone number.');
      } else {
        setPhoneError('');
      }
      return isValid
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const resetForm = () => {
      setName('');
      setEmail('');
      setPhone('');
      setDesignation('');
      setDepartment('');
      setUserLocation('');
    };

    const fetchData = async () => {
      if(email && !validateEmail(email)) {
        return
      }
      if (phone && !validatePhone(phone)) {
          return;
      }
      let courseData =localStorage.getItem("selectedCourseObj")
      courseData = JSON.parse(courseData)
      let orgData =localStorage.getItem("orgData")
      orgData = JSON.parse(orgData)
      let data = {
        "orgid": orgData.org_id,
        "org_course_id":courseData?.org_course_id,
        "course_id": courseData?.course_id,
        "org_name": orgData?.org_name.trim(),
        "org_entity": selectedValue || "NA",
        "users": [
          {
            "fullname": name,
            "email": email.trim(),
            "mobile": phone,
            "designation": designation.trim(),
            "department": department.trim(),
            "location": userLocation.trim(),
          }
        ]
      }
      try {
        const token = apiService.getAuthToken()
        const result = await apiService.post('send-and-assign-course', data, token);
        if (result && result.status === "success") {
          console.log(result, "kcdfnvidsfnvids");
          // setCourseData(result.data);
         CustomToast.success('Invitation sent successfully.')
          resetForm()
          //openModal()
        }
      } catch (error) {
         //   setError('Error fetching user data');
      console.error(error);
      if(error && error.message && error.message.includes('Course invite already sent.')) {
        CustomToast.info('The invitation has already been sent to the user.')
      } else if(error && error.message && error.message.includes('Course invite already sent by some other organization.')){ 
        CustomToast.info('Course invite already sent by some other organization.') 
      } else if(error && error.message && error.message.includes('Not enough licenses available')) {
        CustomToast.info('Insufficient licenses')
      }
      }
    };

    const fetchOrgData = async () => {
      if(email && !validateEmail(email)) {
        return
      }
      if (phone && !validatePhone(phone)) {
          return;
      }
      let courseData =localStorage.getItem("selectedCourseObj")
      courseData = JSON.parse(courseData)
      let orgData =localStorage.getItem("orgData")
      orgData = JSON.parse(orgData)
      let data1 = {
        "orgid": orgData?.org_id,
        "org_course_id":courseData?.org_course_id,
        "course_id": courseData?.course_id,
        "org_name": orgData?.org_name?.trim(),
        "org_entity": selectedValue || "NA",
        "users": [
          {
            "fullname": userInfo?.name,
            "email": userInfo?.email?.trim(),
            "mobile": userInfo?.phone,
            "designation": userInfo?.designation?.trim(),
            "department": userInfo?.department?.trim(),
            "location": userInfo?.location?.trim(),
          }
        ]
      }
      try {
        const token = apiService.getAuthToken()
        const result = await apiService.post('send-and-assign-course', data1, token);
        if (result && result.status === "success") {
          console.log(result, "userInfo1");
          // setCourseData(result.data);
         CustomToast.success('Invitation sent successfully.')
          resetForm()
          //openModal()
        }
      } catch (error) {
         //   setError('Error fetching user data');
      console.error(error);
      if(error && error.message && error.message.includes('Course invite already sent.')) {
        CustomToast.info('The invitation has already been sent to the user.')
      } else if(error && error.message && error.message.includes('Error occurred while sending invitations: Error: Error while creating temp user: Course invite already sent by some other organization.')){ 
        CustomToast.info('Course invite already sent by some other organization.') 
      } else if(error && error.message && error.message.includes('Not enough licenses available')) {
        CustomToast.info('Insufficient licenses')
      }
      }
    };

    return (
        <div className='course-invite-form'>
            <form onSubmit={handleSubmit}>
            {options && options.length > 0 ? <div className="form-group mb-3">
              <label>Entity*</label>
                <select 
                className='form-select position-relative'
                value={selectedValue?.org_id}
                onChange={handleInputChange}
                required
                >
                {options.map((option) => (
                  <option key={option.org_id} value={option.org_entity}>
                    {option.org_entity}
                  </option>
                ))}
                </select>
                {/* <input
                  type="text"
                  name='selectedValue'
                  value={selectedValue || ''}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  className='form-control'
                  placeholder="Add option if not available in list"
                  ref={inputRef}
                /> */}
            </div> : null}
                    <div className="form-group mb-3">
                        <label>Name*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Name'
                            value={name}
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                        required/>
                    </div>
                    <div className="form-group mb-3">
                        <label>Email Id</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder='Email ID'
                            value={email}
                            name="email"
                            onChange={handleEmailChange}
                             />  
                            {emailError && <p className="error">{emailError}</p>}
                    </div>
                    <div className="form-group mb-3">
                        <label>Phone Number</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='xxxxx xxxxx'
                            value={phone}
                            name="phone"
                            onChange={handlePhoneChange} 
                            />
                            {phoneError && <p className="error">{phoneError}</p>}
                    </div>
                    <div className="form-group mb-3">
                        <label>Designation</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='e.g. Designer'
                            value={designation}
                            name="designation"
                            onChange={(e) => setDesignation(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Department</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='e.g. IT'
                            value={department}
                            name="department"
                            onChange={(e) => setDepartment(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-4 location">
                    <label className='mb-2'>Location</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='e.g. Pune'
                        value={userLocation}
                        name="userLocation"
                        onChange={(e) => setUserLocation(e.target.value)} 
                    />
                </div>
                    <button type='submit' className='btn btn-primary w-100'onClick={fetchData}>Send Invite</button>
            </form>

            {/* <div className='or-seperator text-center my-4'><span>OR</span></div> */}

           <div className='text-center mt-2'><Link className='fw-bold'onClick={fetchOrgData}>Assign To Self</Link></div> 
           {/* <button className="btn btn-primary" onClick={openModal}>click here </button> */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Modal"
          className='Modal bg-white p-3'
        >
          
          {/*<button type="button" className="close text-white" onClick={closeModal}><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
          <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
              </svg></button>*/}

          <div className="py-4 px-5 w-100 text-center"><p className="fw-bold font-18 mb-0">Invitation sent successfully.</p>
          <button onClick={closeModal} className='btn btn-md btn-primary mt-4 py-2'>Ok</button>
          </div>
        </Modal>
      
        </div>
        
    )

}

export default InviteCourseForm;

