import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';
import OtpVerification from '../login/otpVerification';
import CustomToast from '../Toast';
import Loader from './loader';
import Messages from '../messages';
import { debounce } from '../../utils/debounce'; // Import the debounce function

const AcceptInvitationComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [otpData, setOtpData] = useState(null);
  const [showOtpComponent, setShowOtpComponent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [retryAccept, setRetryAccept] = useState();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [redirectTimer, setRedirectTimer] = useState(5);
  const [message, setMessage] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  // Debounced version of the verifyToken function
  const debouncedVerifyToken = useCallback(
    debounce(async (paramsToken) => {
      try {
        setLoader(true);
        const data = {
          token: paramsToken,
          status: "ACCEPTED",
          dname: "OPPO",
          did: "2526"
        };
        setRetryAccept(data);
        const result = await apiService.post('accept-and-assign-course', data);
        if (result && result.status === "success") {
          setOtpData(result.data);
          setShowOtpComponent(true);
          CustomToast.success('OTP sent successfully');
        } else {
          setShowOtpComponent(false);
          setError('Failed to verify token');
          CustomToast.error('Failed to verify token');
        }
      } catch (error) {
        setShowOtpComponent(false);
        handleAPIError(error);
        CustomToast.error(error);
      } finally {
        setLoader(false);
      }
    }, 300), // Adjust the debounce delay as needed
    []
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token && !otpData) {
      debouncedVerifyToken(token);
    } else if (!token) {
      navigate('/error');
    }
  }, [location.search, otpData, debouncedVerifyToken]);

  const handleAPIError = (error) => {
    if (error && error.message && error.message.includes('Course already accepted')) {
      setShowErrorPopup(true);
      setMessage('Course already accepted, please try login');
      setIsModalOpen(true);
    } else {
      setError(error.message || 'Something went wrong. Please try again.');
    }
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRedirectTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    if (redirectTimer === 0 && showErrorPopup) {
     // window.location.href = process.env.REACT_APP_LOGIN_URL;
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      if (token) {
        window.location.href = `${process.env.REACT_APP_LOGIN_URL}?token=${token}`
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL
      }
      setIsModalOpen(false);
    }
  }, [redirectTimer, navigate, showErrorPopup]);

  return (
    <div>
      {error && <Messages errorMessage={error} />}
      {loader ? <Loader /> :
        showOtpComponent && <OtpVerification userDetails={otpData} retryAcceptProps={retryAccept} />
      }
      {showErrorPopup && <Messages modalMessage={message} redirectTimer={redirectTimer} />}
    </div>
  );
};

export default AcceptInvitationComponent;
